<template>
  <div class="container">
<div class="top" v-show="isBackShow"><img src="@/assets/icon/icon_back.png" @click="backToTop"></div>
  </div>
</template>

<script>
export default {
  name: "Back",
  data() {
    return {
      //isBackShow: false,
      scrollTop: 0,
      clientHeight: 0,
    };
  },
  computed: {
    isBackShow() {
      return this.scrollTop >= this.clientHeight;
    },
  },
  mounted() {
    this.scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    window.addEventListener("scroll", this.handleScroll, true);
    this.clientHeight = document.documentElement.clientHeight;
  },
  methods: {
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
    backToTop() {
        var tempHeight = this.scrollTop;
        const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = tempHeight -= 100;
        if (tempHeight <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" rel="stylesheet/scss" scoped>
@import "@/assets/styles/function.scss";

.container {
    .top {
        position: fixed;
        bottom: rem(100px);
        height: rem(150px);
        width: 100%;
        z-index: 10;
        img {
            width: rem(150px);
            height: rem(150px);
            background-size: 100% 100%;
            float: right;
            margin-right: rem(10px);
        }
    }
}
</style>
